const TARGET_PAIRS = [
  ["cpp_stl_98", "clang3.4-linux-x86_64"],
  ["cpp_stl_98", "clang11-linux-x86_64"],
  ["cpp_stl_98", "gcc4.8-linux-x86_64"],
  ["cpp_stl_98", "gcc11-linux-x86_64"],
  ["cpp_stl_98", "clang14-macos-x86_64"],
  ["cpp_stl_98", "msvc141-windows-x64"],
  ["cpp_stl_11", "clang3.4-linux-x86_64"],
  ["cpp_stl_11", "clang11-linux-x86_64"],
  ["cpp_stl_11", "gcc4.8-linux-x86_64"],
  ["cpp_stl_11", "gcc11-linux-x86_64"],
  ["cpp_stl_11", "clang14-macos-x86_64"],
  ["cpp_stl_11", "msvc141-windows-x64"],
  ["csharp", "net48-windows-x64"],
  ["csharp", "netcore2.2.103-linux-x86_64"],
  ["csharp", "netcore3.0.100-linux-x86_64"],
  ["csharp", "net6.0-linux-x86_64"],
  ["graphviz", "9-linux-x86_64"],
  ["go", "1.23-linux-x86_64"],
  ["java", "zulu7-linux-x86_64"],
  ["java", "temurin8-linux-x86_64"],
  ["java", "temurin11-linux-x86_64"],
  ["java", "temurin17-linux-x86_64"],
  ["java", "temurin21-linux-x86_64"],
  ["javascript", "nodejs4-linux-x86_64"],
  ["javascript", "nodejs8-linux-x86_64"],
  ["javascript", "nodejs10-linux-x86_64"],
  ["javascript", "nodejs12-linux-x86_64"],
  ["javascript", "nodejs20-linux-x86_64"],
  ["lua", "5.3-linux-x86_64"],
  ["nim", "1.6.0-linux-x86_64"],
  ["perl", "5.24-linux-x86_64"],
  ["perl", "5.40-linux-x86_64"],
  ["php", "7.1-linux-x86_64"],
  ["php", "8.3-linux-x86_64"],
  ["python", "2.7-linux-x86_64"],
  ["python", "3.4-linux-x86_64"],
  ["python", "3.12-linux-x86_64"],
  ["construct", "python2.7-linux-x86_64"],
  ["construct", "python3.4-linux-x86_64"],
  ["construct", "python3.12-linux-x86_64"],
  ["ruby", "1.9-linux-x86_64"],
  ["ruby", "2.3-linux-x86_64"],
  ["ruby", "3.3-linux-x86_64"],
  ["rust", "1.81-linux-x86_64"],
];
TARGET_PAIRS.forEach(pair => Object.freeze(pair));
Object.freeze(TARGET_PAIRS);

export default TARGET_PAIRS;
